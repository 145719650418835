import React, { useState, useEffect, useRef } from "react";
import logo from "../images/nav/logo.png";
import styles from "./nav.module.css";
import { Link } from "gatsby";
import burgerMenu from "../images/nav/menu.svg";
import LoyaltyPoints from "./LoyaltyPoints/LoyaltyPoints";
import { useTranslation } from "react-i18next";
import en from "../images/nav/en.png";
import fr from "../images/nav/fr.png";

export default function Nav() {
  const [showModal, setShowModal] = useState(false);

  const [showMobNav, setShowMobNav] = useState(() => {
    if (typeof window !== `undefined`) {
      return window.innerWidth < 1020 ? false : true;
    }
  });

  const navRef = useRef(null);
  const handleClick = () => {
    showMobNav ? setShowMobNav(false) : setShowMobNav(true);
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      // listen to scroll and change nav background color
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) {
            cancelAnimationFrame(frame);
          }
          frame = requestAnimationFrame(() => {
            fn(...params);
          });
        };
      };
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
      };

      document.addEventListener("scroll", debounce(storeScroll), {
        passive: true,
      });
      storeScroll();

      // close nav with click or esc key
      if (window.innerWidth < 1020) {
        const closeMenuWithEscKey = (e) => {
          if (e.keyCode === 27 && showMobNav === true) {
            setShowMobNav(false);
          }
        };

        const closeMenuWithClick = (e) => {
          if (navRef.current && !navRef.current.contains(e.target)) {
            if (!showModal) {
              setShowMobNav(false);
            }
          }
        };
        document.addEventListener("keydown", closeMenuWithEscKey);
        document.addEventListener("mousedown", closeMenuWithClick);

        window.addEventListener("resize", handleResize);
        return () => {
          document.removeEventListener("keydown", closeMenuWithEscKey);
          window.removeEventListener("resize", handleResize);
          document.removeEventListener("mousedown", closeMenuWithClick);
        };
      }
      // show nav on window resize on desktop and hide on mobile
      function handleResize() {
        if (window.innerWidth > 1019) {
          if (showMobNav === false) setShowMobNav(true);
        }
      }

      return () => {
        document.removeEventListener("scroll", debounce(storeScroll));
      };
    }
  }, [showModal, showMobNav]);

  const { t, i18n } = useTranslation("translation", { useSuspense: false });

  const links = [
    [t("nav.home"), "/"],
    [t("nav.la noce"), "/restaurant"],
    [t("nav.menu"), "tab"],
    [t("nav.entertainment"), "/entertainment"],
    [t("nav.contact"), "/contact"],
    [t("nav.loyaltyPoints"), "points"],
  ];

  const dropdown = (
    <React.Fragment key="dropdown">
      <p
        className={`${styles.link} ${styles.dropdown}`}
        style={{ margin: 0 }}
        tabIndex="0"
      >
        Menu &#x25BC;
      </p>
      <div className={styles.dropdownLinks} id="dropdown">
        <div className={styles.dropdownWrapper}>
          <Link to="/food" className={`${styles.link} ${styles.tabLink}`}>
            {t("nav.food")}
          </Link>
          <Link to="/cocktails" className={`${styles.link} ${styles.tabLink}`}>
            {t("nav.cocktails")}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <header className={styles.header}>
      <img
        src={logo}
        alt="logo"
        style={{ width: "175px" }}
        className={styles.logo}
      />
      <div ref={navRef}>
        {showMobNav ? (
          <nav className={styles.nav}>
            {links.map((link, i) =>
              link[1] === "tab" ? (
                dropdown
              ) : link[1] === "points" ? (
                <LoyaltyPoints
                  show={showModal}
                  setShow={setShowModal}
                  key="points-modal"
                />
              ) : (
                <Link to={link[1]} className={styles.link} key={i}>
                  {link[0]}
                </Link>
              )
            )}
          </nav>
        ) : null}
        <div style={{ display: "inline-block" }}>
          <button
            onClick={() => i18n.changeLanguage("en")}
            className={styles.lanBtns}
          >
            <img src={en} alt="select english" />
          </button>
          <button
            onClick={() => i18n.changeLanguage("fr")}
            className={styles.lanBtns}
          >
            <img src={fr} alt="select french" />
          </button>
        </div>
        <button className={styles.burgerMenu} onClick={handleClick}>
          <img src={burgerMenu} alt={showMobNav ? "Close Menu" : "Open Menu"} />
        </button>
      </div>
    </header>
  );
}
