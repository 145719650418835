import React from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import styles from "./pointsForm.module.css";

export default function PointsForm({
  user,
  setUser,
  submit,
  userError,
  setUserError,
}) {
  const handleChange = (event) => {
    if (event.target.name === "typeSelect") {
      if (userError) setUserError(false);
      setUser({ ...user, type: event.target.value });
    } else setUser({ ...user, [event.target.name]: event.target.value });
  };
  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <form className={styles.form} onSubmit={submit}>
      <div className={styles.inputsContainer}>
        <FormControl
          style={{
            minWidth: "180px",
            marginRight: "15px",
            marginBottom: "30px",
          }}
        >
          <InputLabel id="type">{t("points.type.title")}</InputLabel>
          <Select
            labelId="type"
            id="typeSelect"
            value={user.type}
            onChange={handleChange}
            name="typeSelect"
            required
          >
            <MenuItem value="full name">{t("points.form.name")}</MenuItem>
            <MenuItem value="email address">{t("points.form.email")}</MenuItem>
            <MenuItem value="phone number">{t("points.form.phone")}</MenuItem>
          </Select>
          <FormHelperText>{t("points.type.helper")}</FormHelperText>
        </FormControl>
        {user.type === "full name" ? (
          <>
            <TextField
              id="first name"
              label={userError ? "Error" : t("points.form.first")}
              name="first name"
              onChange={handleChange}
              value={user["first name"]}
              type="text"
              error={userError}
              style={{ marginRight: "15px" }}
              helperText={userError ? "No user found" : ""}
              required
            />
            <TextField
              id="last name"
              label={userError ? "Error" : t("points.form.last")}
              name="last name"
              onChange={handleChange}
              value={user["last name"]}
              type="text"
              error={userError}
              helperText={userError ? "No user found" : ""}
              required
            />
          </>
        ) : (
          user.type && (
            <TextField
              id="textField"
              label={
                userError
                  ? "Error"
                  : user.type === "email address"
                  ? t("points.form.enterEmail")
                  : t("points.form.enterPhone")
              }
              name={user.type}
              onChange={handleChange}
              value={user[user.type]}
              type={
                user.type === "email address"
                  ? "email"
                  : user.type === "phone number" && "number"
              }
              error={userError}
              helperText={userError ? "No user found" : ""}
              required
              fullWidth
            />
          )
        )}
      </div>
      <Button variant="outlined" color="primary" type="submit">
        {t("points.form.button")}
      </Button>
    </form>
  );
}
