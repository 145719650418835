import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import styles from "./loyaltyPoints.module.css";
import PointsForm from "./PointsForm";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import loading from "../../images/nav/loading.png";
import FreeItems from "./FreeItems";
import { useTranslation } from "react-i18next";

export default function LoyaltyPoints({ show, setShow }) {
  // props for modal open close state
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isFetching, setFetching] = useState(false);
  const [userError, setUserError] = useState(false);
  // Points form state and fetched customer id & points
  const initUser = () =>
    JSON.parse(window.localStorage.getItem("user")) || {
      "first name": "",
      "last name": "",
      "email address": "",
      "phone number": "",
      type: "",
      id: null,
      points: null,
    };
  const [user, setUser] = useState(initUser);

  useEffect(() => {
    window.localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  // api credentials
  const apiUser = "fiverr@test.com";
  const api_key = "SUZWMMAVE2O69NI437IFWOEQG4934D7MYX9";
  const restApiUrl = "https://lanoce.hiboutik.com/api/";

  // called after getting points of customer id with api call
  const displayPoints = (points) => {
    if (isFetching) setFetching(false);
    if (user.points !== points) setUser({ ...user, points });
  };

  // main function that makes two types of api call
  // first get customer details from search then points from id
  const makeApiCall = (apiUrl, type) => {
    let request = new XMLHttpRequest();
    request.onload = function () {
      if (this.readyState === 4) {
        if (this.status === 200 || this.status === 201) {
          const response = JSON.parse(this.response);
          if (type === "customer") {
            if (!response[0]) {
              setFetching(false);
              setUserError(true);
            } else setUser({ ...user, id: response[0].customers_id });
          } else displayPoints(response[0].loyalty_points);
        } else {
          setFetching(false);
          alert("Error Try Again");
          console.log("API error:", this);
        }
      }
    };
    request.open("GET", apiUrl, true);
    request.setRequestHeader(
      "Authorization",
      "Basic " + btoa(apiUser + ":" + api_key)
    );
    request.send();
  };

  // call api function passing customer info to get id and then points
  const getCustomer = (customer, type) => {
    let url = restApiUrl + "customers/search/";
    if (type === "full name") {
      url += `?last_name=${customer[1]}&first_name=${customer[0]}`;
    } else if (type === "email address") {
      url += `?email=${customer}`;
    } else {
      url += `?phone=${customer}`;
    }
    setFetching(true);
    makeApiCall(url, "customer");
  };

  // form submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.type === "full name")
      getCustomer([user["first name"], user["last name"]], user.type);
    else if (user.type === "email address")
      getCustomer(user["email address"], user.type);
    else getCustomer(user["phone number"], user.type);
  };

  //------

  // get latest loyalty points on every render
  useEffect(() => {
    if (typeof user.id === "number") {
      makeApiCall(`https://lanoce.hiboutik.com/api/customer/${user.id}`);
    }
  });

  const handleUserChange = () => {
    setUser({
      "first name": "",
      "last name": "",
      "email address": "",
      "phone number": "",
      type: "",
      id: null,
      points: null,
    });
  };

  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <>
      <button className={styles.link} onClick={handleShow}>
        {t("nav.loyaltyPoints")}
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("points.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isFetching ? (
            <img src={loading} alt="loading" className={styles.loader} />
          ) : typeof user.id === "number" ? (
            <>
              <p className={styles.userInfo} style={{ marginTop: "16px" }}>
                <span>User: </span>{" "}
                {user.type === "full name"
                  ? `${user["first name"]} ${user["last name"]}`
                  : user[user.type]}
                <IconButton
                  aria-label="delete"
                  onClick={handleUserChange}
                  classes={{ root: styles.deleteIcon }}
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              </p>
              <p className={styles.userInfo}>
                <span>{t("points.points")}: </span> {user.points}
              </p>
              {user.points > 34 && <FreeItems points={user.points} />}
            </>
          ) : (
            <PointsForm
              user={user}
              setUser={setUser}
              submit={handleSubmit}
              setUserError={setUserError}
              userError={userError}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
