import React from "react";
import salades from "../Food/menuGastro/salades.json";
import pizzas from "../Food/menuGastro/pizzas.json";
import { useTranslation } from "react-i18next";

export default function FreeItems({ points }) {
  const menu = [...salades.salades, ...pizzas.pizzas];
  let pointsToDH = points / 5;
  let items = [];
  for (let item of menu) {
    if (item.price < pointsToDH) {
      items.push(item.item);
      pointsToDH -= item.price;
    }
    if (pointsToDH < 35) break;
    if (items.length === 5) {
      items.push("and much more...");
      break;
    }
  }
  const { t } = useTranslation("translation", { useSuspense: false });

  return (
    <div>
      <p style={{ fontWeight: 600 }}>{t("points.freeItems")}</p>
      {items.map((item, i) => (
        <p style={{ fontWeight: 400 }} key={i}>
          {item}
        </p>
      ))}
    </div>
  );
}
